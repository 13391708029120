<template>
    <div class="dropdown float-right mt-1">
      <b-dropdown
          no-caret
          right
          toggle-class="p-0 mr-1"
          variant="link"
      >
        <template #button-content>
          <feather-icon
              class="align-middle text-body"
              icon="MoreVerticalIcon"
              size="16"
          />
        </template>
        <b-dropdown-item
            @click="isProfileSidebar = true"
        >
          <feather-icon
              class="mr-50"
              icon="UserIcon"
              size="16"
          />
          <span class="font-weight-bolder"> View profile </span>
        </b-dropdown-item>
        <b-dropdown-item>
          <feather-icon
              class="mr-50"
              icon="TrashIcon"
              size="16"
          />
          <span class="font-weight-bolder"> Delete chat </span>
        </b-dropdown-item>
      </b-dropdown>


      <ProfileSidebar
          ref="AddLocationSideBar"
          :is-profile-sidebar.sync="isProfileSidebar"
          @toggleSideBars="toggleSideBars()"
      />

    </div>

</template>

<script>

import ProfileSidebar from '@/views/chat/sidebar/ProfileSideBar'
import {BDropdown, BDropdownItem} from 'bootstrap-vue'

export default {
  components: {
    BDropdown, BDropdownItem, ProfileSidebar,
  },
data() {
  return {
    isProfileSidebar: false,

  }
},
methods: {
  toggleSideBars() {
    this.isProfileSidebar = !this.isProfileSidebar
  },
}
}
</script>

<style scoped>

</style>
