<template>
<div>
  <main class="">
    <div >


      <div class="card">
        <div class="row g-0">

                <!-- Contact Left Side Bar -->
        <div class="col-12 col-lg-5 col-xl-3 border-right">
                 <!-- Avatar and search Bar -->
            <div class=" d-none d-md-block">
              <div class="d-flex align-items-center">
                  <b-avatar
                                size="40"
                                variant="light-primary"
                                badge
                                :src="require('@/assets/images/avatars/13-small.png')"
                                class="badge-minimal ml-2 mr-1 "
                                badge-variant="success"
                              />
                <div class="flex-grow-1">

                   <b-input-group class="input-group-merge  my-1">
                                  <b-input-group-prepend is-text>
                                      <feather-icon icon="SearchIcon" />
                                  </b-input-group-prepend>
                                  <b-form-input
                                      id="icons-search"
                                      v-model="seachQuery"
                                      placeholder="Search"
                                  />
                                  </b-input-group>
                </div>

              </div>
              <div><hr></div>
            </div>

                 <!-- Chat Contacts -->
             <vue-perfect-scrollbar
                      v-if="chats.length"
                      :settings="perfectScrollbarSettings"
                      class="scrollable-container media-list scroll-area"
                      style="height: 640px; solid #E5E5E5"
              >


                      <b-media v-for="chat in chats" :key="chat.subtitle" >
                             <div class="list-group-item list-group-item-action border-0 mb-n1 mt-n1">
                              <b-row >

                                      <!-- Left Side -->

                                 <b-col cols="11"  class="pb-0 ">
                                      <div class="row">



                                          <!--02  Avatar Name & Time -->

                                          <div class="d-flex align-items-center ">
                                            <b-avatar v-if="chat.avatar"
                                                      size="40"
                                                      :src="chat.avatar"
                                                       badge
                                                       class="badge-minimal"
                                                       :badge-variant="chat.online"
                                            />
                                            <b-avatar v-else  size="40"
                                                      :variant="chat.bg"
                                                      :text="chat.a"
                                                      >

                                                  <feather-icon
                                                  :icon="chat.icon"

                                                  size="18"  />
                                            </b-avatar>
                                            <div class="ml-1">
                                                <p class="mb-0 font-weight-bolder text-dark" > {{ chat.name }}</p>
                                                <small class="text-secondary">{{ chat.subtitle }}</small>
                                            </div>
                                          </div>
                                      </div>
                                    </b-col>

                                         <!-- Right side Trash button -->

                                    <b-col cols="1"  class="d-flex justify-content-end ">
                                        <b-row >
                                          <div ><span><small>{{ chat.time}}</small></span></div>
                                            <div class="ml-1">

                                                <b-avatar
                                                    :text="chat.msg"
                                                    :variant="chat.color"
                                                    size="sm"
                                                  />
                                                                                                                          </div>
                                          </b-row>
                                      </b-col>
                                </b-row>
                        </div>
                      </b-media>


              </vue-perfect-scrollbar>

              <!-- No Chat Contacts -->
              <div  v-if="!chats.length"  class="text-center" >
                  <b-card class="d-flex align-items-center bg-white"
                  style="height: 640px; solid #E5E5E5"
                  >
                  <div style="height: 200px; solid #E5E5E5">
                  </div>

                      <div class="ml-n1">
                          <div class="mb-2"
                          >
                             <img class="img-fluid" src="@/assets/images/pages/no-chat.png" alt="">
                          </div>
                          <h5>  No Chats  </h5>
                      </div>
                  </b-card>
               </div>

              <!-- Chat Footer -->
              <div><hr></div>
                <div class="mr-1">
                 <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            block
                            class="mt-2 mx-1 mb-2 "
                    >
                        <feather-icon icon="PlusIcon" class="mr-50"/>
                        Start New Chat
                  </b-button>
                 </div>

         </div>

                <!-- Contact Right Side Chat -->
          <div class="col-12 col-lg-7 col-xl-9">


                         <!-- Right Side Chat -->
                 <div  v-if="contacts.length"    >

                                <!-- Chat Header Bar -->
                            <div class="mb-1 " v-for="contact in contacts" :key="contact.subtitle" >

                                <!-- Personal Chat -->
                                    <div v-if="contact.avatar" >
                                            <div class=" px-1 border-bottom d-none d-lg-block">
                                            <div class="d-flex align-items-center py-1 mb-1">
                                                <div class="position-relative">
                                                    <b-avatar
                                                                size="40"
                                                                    :src="contact.avatar"
                                                                    badge
                                                                    class="badge-minimal mr-1"
                                                                    :badge-variant="contact.online"
                                                            >
                                                    </b-avatar>
                                                    <strong>{{ contact.name }}</strong>
                                                </div>
                                                <div class="flex-grow-1 ">
                                                    <ChatDropDownMenu/>

                                                </div>
                                            </div>
                                            </div>
                                    </div>

                                <!-- Group Chat -->

                                    <div v-else >
                                        <div class=" px-1 border-bottom d-none d-lg-block">
                                            <div class="d-flex align-items-center py-1">
                                                <div class="position-relative ">
                                                <b-avatar
                                                    size="40"
                                                    :variant="contact.bg"
                                                    :text="contact.a"
                                                    class="mr-1"
                                                    >

                                                    <feather-icon
                                                    :icon="contact.icon"
                                                    size="18"  />
                                                </b-avatar>
                                                 </div>
                                                <div >
                                                      <strong>{{ contact.name }}</strong>
                                                       <div class=" small"><em>{{ contact.member }} Members</em></div>
                                                  </div>
                                                      <div class="flex-grow-1 mr-1">
                                                          <div class="d-flex justify-content-end ">
                                                     <b-avatar-group
                                                                size="34px"
                                                            >
                                                            <b-avatar
                                                                v-for="avatar in [5,7,8,9]"
                                                                :key="avatar.avatar"
                                                                v-b-tooltip.hover.bottom="avatar.fullName"
                                                                :src="require(`@/assets/images/avatars/${avatar}-small.png`)"
                                                                class="pull-up"
                                                            />
                                                            <h6 class="align-self-center cursor-pointer ml-1 mb-0">
                                                                +2
                                                            </h6>
                                                    </b-avatar-group>
                                                          </div>

                                                </div>
                                                <div>
                                                    <GroupChatDropDownMenu/>
                                                </div>
                                            </div>
                                            </div>
                                    </div>
                            </div>

                                <!-- Chat -->
                                <div class="position-relative bg-light">
                                                        <div class="chat-messages p-2 ">

                                <vue-perfect-scrollbar
                                    v-if="chats.length"
                                    :settings="perfectScrollbarSettings"
                                    class="scrollable-container media-list scroll-area "
                                >


                                        <b-media v-for="msg in msgs" :key="msg.subtitle" >
                                            <div class="list-group-item list-group-item-action border-0 mb-n1 mt-n1 bg-light">


                                  <div v-if="msg.id" >

                                    <div class="chat-message-right " >
                                        <div>
                                          <b-avatar size="40"  :src="msg.avatar" />
                                        </div>
                                        <div class="flex-shrink-1 bg-white rounded py-1 px-1 mr-1">
                                        <div class="font-weight-bold "> {{msg.message}}</div>
                                        <div class="chat-message-right small text-nowrap mt-1">{{msg.time}}</div>
                                        </div>
                                    </div>
                                  </div>
                                  <div v-if="!msg.id" >
                                   <div class="chat-message-left " >
                                        <div>
                                          <b-avatar size="40"  :src="msg.avatar" />
                                        </div>
                                        <div class="flex-shrink-1 bg-light-info rounded py-1 px-1 ml-1">
                                        <div class="font-weight-bold text-dark">{{msg.message}}</div>
                                        <div class="chat-message-right small text-nowrap mt-1 text-dark">{{msg.time}}</div>
                                        </div>
                                    </div>
                                  </div>

                                            </div>
                                        </b-media>

                                </vue-perfect-scrollbar>
                                </div>
                                                    </div>


                                <!-- Chat Footer -->
                            <div class="flex-grow-0 py-3 px-4 border-top ">

                            <div class="input-group ">
                                <input type="text" class="form-control mr-1" placeholder="Type your message">
                                <button class="btn btn-dark ">
                                    <div class="mx-1">
                                    Send
                                    </div>
                                    </button>
                            </div>
                            </div>
                  </div>
                         <!-- No Contact Notifications -->
                  <div  v-if="!contacts.length"  class="text-center" >
                       <b-card class="d-flex align-items-center"
                          style="height: 800px; solid #E5E5E5"
                         >
                             <div style="height: 150px; solid #E5E5E5">
                            </div>

                           <div >
                              <div class="mb-2 d-flex justify-content-center ">
                                 <img class="img-fluid" src="@/assets/images/pages/start-chat.png" alt="">
                              </div>
                               <div  class="d-flex justify-content-center ">
                                  <h3>Start a chat</h3>
                               </div>
                                <div  class="d-flex justify-content-center ">
                                      <h4>Click on existing chat or start conversation </h4>
                                </div>
                                <div  class="d-flex justify-content-center ">
                                       <h4>with new contacts</h4>
                                </div>
                              </div>
                            </b-card>
                   </div>

          </div>
        </div>
      </div>
    </div>
  </main>
</div>
</template>

<script>
import ChatDropDownMenu from "@/views/chat/dropdowns/ChatDropDownMenu";
import GroupChatDropDownMenu from "@/views/chat/dropdowns/GroupChatDropDownMenu";

 import { BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox, BRow, BCol, BCard, BForm, BFormGroup, BInputGroup, BInputGroupPrepend,
 BFormInput, BListGroup, BListGroupItem ,BAvatarGroup} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    BCard,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
     BFormInput, BListGroup, BListGroupItem,
     ChatDropDownMenu,
      GroupChatDropDownMenu,
      BAvatarGroup,
       },
  directives: {
    Ripple,
  },
  setup() {
    /* eslint-disable global-require */

    const chats = [

      {
        name: 'Courtney Henrry',
        avatar: require('@/assets/images/avatars/9-small.png'),
        subtitle: 'Hi, is there anyone available for next shift ...',
        icon: '',
        bg: '',
        color: 'danger',
        msg:'1',
       time:'17:49',
       online:"success",
      },
      {
        name: 'Jason Momoa',
        avatar: require('@/assets/images/avatars/5-small.png'),
        subtitle: 'See you soon!',
        icon:'',
        bg: '',
        color:'danger',
        msg: '2',
        time:'16:13',
        online:"success",
      },
      {
        name: 'Group chat',
        avatar: '',
        subtitle: 'Abbie: We can make a new shift for that.',
        icon: 'UsersIcon',
        bg: 'light-success',
        color:'white',
        msg:'',
        time:'12:53',
        online:"",
      },
      {
        name: 'COVID-19 Vaccine Program',
        avatar: '',
        subtitle: 'I will adjust it a little bit later. Right?',
        icon: '',
        a:'C',
        bg: 'light-danger',
        color:'white',
        msg:'',
        online:"success",
        time:'08:14'
      },
       {
        name: 'Bessie Cooper',
        avatar: require('@/assets/images/avatars/1-small.png'),
        subtitle: 'Hi, is there anyone available for next shift ...',
        icon: '',
        bg: '',
        color: '',
        msg:'',
        online:"success",
        time:'Yesterday'

      },
      {
        name: 'Jason Momoa',
        avatar: require('@/assets/images/avatars/2-small.png'),
        subtitle: 'See you soon!',
        icon:'',
        bg: '',
        color:'',
        msg: '',
        online:"",
        time:'Yesterday'
      },
       {
        name: 'Kathryn Murphy',
        avatar: require('@/assets/images/avatars/3-small.png'),
        subtitle: 'Hi, is there anyone available for next shift ...',
        icon: '',
        bg: '',
        color: '',
        msg:'',
        online:"success",
        time:'Yesterday'

      },
      {
        name: 'Wade Warren',
        avatar: require('@/assets/images/avatars/4-small.png'),
        subtitle: 'See you soon!',
        icon:'',
        bg: '',
        color:'',
        msg: '',
        online:"",
        time:'Yesterday'
      },
       {
        name: 'Cody Fisher',
        avatar: require('@/assets/images/avatars/6-small.png'),
        subtitle: 'Hi, is there anyone available for next shift ...',
        icon: '',
        bg: '',
        color: '',
        msg:'',
        online:"success",
        time:'Yesterday'

      },
      {
        name: 'Annette Black',
        avatar: require('@/assets/images/avatars/7-small.png'),
        subtitle: 'See you soon!',
        icon:'',
        bg: '',
        color:'',
        msg: '',
        online:"",
        time:'Yesterday'
      },
       {
        name: 'Annette Black',
        avatar: require('@/assets/images/avatars/10-small.png'),
        subtitle: 'Hi, is there anyone available for next shift ...',
        icon: '',
        bg: '',
        color: '',
        msg:'',
        online:"",
        time:'Yesterday'

      },
      {
        name: 'Cody Fisher',
        avatar: require('@/assets/images/avatars/11-small.png'),
        subtitle: 'See you soon!',
        icon:'',
        bg: '',
        color:'',
        msg: '',
        online:"",
        time:'Yesterday'
      },


    ]

    const contacts = [

            {
         name: 'Courtney Henrry',
         avatar: require('@/assets/images/avatars/9-small.png'),
         subtitle: 'Hi, is there anyone available for next shift ...',
         icon: '',
         bg: '',
         color: 'danger',
         msg:'1',
        time:'17:49',
        online:"success",
       },

      // {
      //   name: 'Group chat',
      //   avatar: '',
      //   subtitle: 'Abbie: We can make a new shift for that.',
      //   icon: 'UsersIcon',
      //   bg: 'light-success',
      //   color:'white',
      //   msg:'',
      //   time:'12:53',
      //   online:"",
      //   member:"6",
      // },
    ]

    const msgs = [

      {
        id: '',
        avatar: require('@/assets/images/avatars/9-small.png'),
        message: 'One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible bed.',
        time:'15:03',
      },
       {
        id: '',
        avatar: require('@/assets/images/avatars/9-small.png'),
        message: 'Good dry grass creature winged given after.',
        time:'15:04',
      },
      {
        id: '1',
        avatar: require('@/assets/images/avatars/13-small.png'),
        message: 'Grass creature good dry winged given after.',
        time:'15:39',
      },
       {
        id: '1',
        avatar: require('@/assets/images/avatars/13-small.png'),
        message: 'Above first whose form above night.',
        time:'15:41',
      },
       {
        id: '',
        avatar: require('@/assets/images/avatars/9-small.png'),
        message: 'Shall male the fish. Subdue appear said theyare make heaven replenish lights evening appear canot abundantly. Also they are beast. Fish good two over creature heaven.',
        time:'17:49',
      },
       {
        id: '',
        avatar: require('@/assets/images/avatars/9-small.png'),
        message: 'Hi, is there anyone available for next shift in London Town Hall for the Vaccination Program?',
        time:'17:49',
      },


    ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }



    return {
      chats,
      contacts,
      msgs,
      perfectScrollbarSettings,
    }
  },
}
</script>
<style >
body{margin-top:20px;}

.chat-online {
  color: #34ce57
}

.chat-offline {
  color: #e4606d
}

.chat-messages {
  display: flex;
  flex-direction: column;
  max-height: 650px;
  min-height: 650px;
  overflow-y: scroll
}

.chat-message-left,

.chat-message-right {
  display: flex;
  flex-shrink: 0;

}

.chat-message-left {
  margin-right: auto;
  max-width: 680px;
}

.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto;
  max-width: 470px;
}
.py-3 {
  padding-top: 1rem!important;
  padding-bottom: 1rem!important;
}
.px-4 {
  padding-right: 1.5rem!important;
  padding-left: 1.5rem!important;
}
.flex-grow-0 {
  flex-grow: 0!important;
}
.border-top {
  border-top: 1px solid #dee2e6!important;
}
</style>
