<template>
  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isProfileSidebar"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-profile-sidebar', val)"
      @hidden="resetForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-5 py-1">
        <h4 class="mb-0">

        </h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer ref="refFormObserver" >
        <!-- Form -->

        <b-card>

          <div class="row g-0">

            <!-- Contact Left Side Bar -->
            <div class="col-12 d-flex justify-content-center">

                  <b-avatar
                      size="130px"
                      variant="light-primary"
                      badge
                      :src="require('@/assets/images/avatars/9-small.png')"
                      class="badge-minimal mt-n2 "
                      badge-variant="success"
                  />

            </div>
          </div>

          <div class="col-12 d-flex justify-content-center mt-1">
            <h2 class=" font-weight-700 ">Courtney Henrry</h2>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <b-alert   variant="primary" show>
              <small class="text-dark font-weight-700 mx-1 ">Occupational Therapist
              </small>
            </b-alert>
          </div>


          <div class="col-12 d-flex justify-content-start mt-1">
            <h6 class=" font-weight-700 ">Personal Info</h6>
          </div>

          <div class="col-12 d-flex justify-content-start">
            <div class="p-2 ml-n2">
              <div class="col-12 d-flex justify-content-start ">
                <feather-icon icon="PhoneIcon"/>
                <div class="ml-1"> 078 4914 9377</div>
              </div>
              <div class="col-12 d-flex justify-content-start mt-1">
                <feather-icon icon="MailIcon"/>
                <div class="ml-1"> courtney.henry@mp.co.uk</div>
              </div>
              <div class="col-12 d-flex justify-content-start mt-1">
                <feather-icon icon="PocketIcon"/>
                <div class="ml-1"> </div>
              </div>


            </div>
          </div>


          <div class="mt-n1"><hr></div>


          <div class="col-12 d-flex justify-content-start mt-2">
            <h6 class=" font-weight-700 ">Professional Info</h6>
          </div>

          <div class="col-12 d-flex justify-content-start">
            <div class="p-2 ml-n2">
              <div class="col-12 d-flex justify-content-start ">
                  Qualification
              </div>
              <div class="col-12 d-flex justify-content-start mt-1">
                Speciality
              </div>
              <div class="col-12 d-flex justify-content-start mt-1">
                IT System
              </div>
              <div class="col-12 d-flex justify-content-start mt-1">
                Smart Card Number
              </div>

            </div>
            <div class="p-2 ml-n2">
              <div class="col-12 d-flex justify-content-start ">
                <h5 class="mb-0 font-weight-700">
                  MRCGP
                </h5>
              </div>
              <div class="col-12 d-flex justify-content-start mt-1">
                <h5 class="mb-0 font-weight-700">
                  Child Protection
                </h5>
              </div>
              <div class="col-12 d-flex justify-content-start mt-1">
                <h5 class="mb-0 font-weight-700">
                  EMIS WEB
                </h5>
              </div>
              <div class="col-12 d-flex justify-content-start mt-1">
                <h5 class="mb-0 font-weight-700">
                  123456789
                </h5>
              </div>
            </div>

          </div>

          <div class="col-12 d-flex justify-content-start mt-n2">
            <div class="p-2 ml-n2">
              <div class="col-12 d-flex justify-content-start ">
                Skills
              </div>
            </div>
          </div>
          <div class="col-12 d-flex justify-content-start mt-n2">
            <div class="p-2 ml-n2">
              <div class="col-12 d-flex justify-content-start ">
                <b-row class="pl-0 ">
                  <b-col class="mt-1 font-weight-light" cols="12">
                    <b-badge class="mr-1" variant="light-info">
                      <h6 >Dietology</h6>
                    </b-badge>
                    <b-badge class="mr-1 my-1 my-md-0" variant="light-info">
                      <h6 > Food Processing</h6>
                    </b-badge>
                    <b-badge class="mr-1" variant="light-info">
                      <h6 > Nutrition</h6>
                    </b-badge>
                    <b-badge class="mr-1  mb-1 my-md-0" variant="light-info">
                      <h6 >Biochemistry</h6>
                    </b-badge>
                  </b-col>

                </b-row>
              </div>
            </div>
          </div>
          <div class="mt-n1"><hr></div>


          <div class="col-12 d-flex justify-content-start mt-2">
            <h6 class=" font-weight-700 "> Availability Today</h6>
          </div>

          <div class="col-12 d-flex justify-content-start">
            <div class="p-2 ml-n2">
              <div class="col-12 d-flex justify-content-start mb-1">
                      <div><b-avatar variant="success" rounded size="10" >
                        <feather-icon icon="CheckIcon" size="1" />
                      </b-avatar></div>
                <span class="ml-1"> 09:00 - 12:00</span>
              </div>
              <div class="col-12 d-flex justify-content-start mb-1">
                <div><b-avatar variant="success" rounded size="10" >
                  <feather-icon icon="CheckIcon" size="1" />
                </b-avatar></div>
                <span class="ml-1"> 14:00 - 18:00</span>
              </div>
          </div>
          </div>



          <div class="mt-n1"><hr></div>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              class=" mx-1 "
          >
            View Full Profile
          </b-button>
        </b-card>

      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>


import {
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BSidebar,
  BTab,
  BTabs,
  BCard,
  BAvatar,
    BBadge,

} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Ripple from 'vue-ripple-directive'


export default {
  methods: {
    toggle() {
      this.$emit('toggleSideBars')
    }
  },
  components: {
    BAvatar,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    VuePerfectScrollbar,
    draggable,
    BTab,
    BTabs,
    BCard,
    BBadge,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isProfileSidebar',
    event: 'update:is-profile-sidebar'
  },
  props: {
    isProfileSidebar: {
      type: Boolean,
      required: true
    },
    roleOptions: {
      type: Array,
      required: false
    },
    planOptions: {
      type: Array,
      required: false
    }
  },
  data () {
    return {

    }
  },
  setup (props, {emit}) {

  }
}
</script>

<style lang="scss">

</style>
